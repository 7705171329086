var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto","md":"12"}},[_c('v-card',{style:({
          borderRadius: '10px',
          margin: '4px',
          backgroundColor: _vm.$cv('primary') + '20',
        }),attrs:{"border":""}},[_c('h2',{staticClass:"text-center"},[_vm._v(" Pedido Realizado ")]),(_vm.sourceVentaElementosAduanaSt)?_c('ClienteAduana',{attrs:{"cliente":_vm.clienteLocal}}):_vm._e(),_c('hr',{}),_c('div',{staticClass:"items__div"},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"py-3"},[_c('div',{staticClass:"div__vfor"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"auto","md":"2"}},[_vm._v(" "+_vm._s(item.codigo)+" ")]),_c('v-col',{attrs:{"cols":"auto","md":"6"}},[_vm._v(" "+_vm._s(item.nombre)+" Precio por Unidad: $"+_vm._s(item.precio ? _vm.$formatMoney(item.precio) : "")+" ")]),_c('v-col',{attrs:{"cols":"auto","md":"2"}},[_vm._v(" Cantidad: "+_vm._s(item.cantidad)+" ")]),_c('v-col',{staticClass:"text-end",attrs:{"cols":"auto","md":"2"}},[_vm._v(" $"+_vm._s(_vm.$formatMoney(item.subtotal))+" ")])],1),(index + 1 < _vm.items.length)?_c('hr'):_vm._e()])}),(
              _vm.tramite.tipogasto != 'Saldo a Favor:' &&
              _vm.tramite.tipogasto != 'Débito:'
            )?_c('div',[_c('hr'),_c('div',{staticClass:"div__gastos py-3 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.tramite.tipogasto)+"   $"+_vm._s(_vm.$formatMoney(_vm.tramite.gasto))+" ")])]):_vm._e()],2),_c('hr',{}),(
            _vm.tramite.tipogasto == 'Saldo a Favor:' ||
            _vm.tramite.tipogasto == 'Débito:'
          )?_c('div',{staticClass:"div_total"},[_c('div',{staticClass:"div_total"},[_c('span',{staticClass:"px-6 my-4 text-end"},[_vm._v("Forma de pago")]),_c('span',{staticClass:"px-6 my-4 text-start"},[_vm._v(" "+_vm._s(_vm.tramite.tipogasto)+"   "+_vm._s(_vm.tramite.gasto)+" ")])]),_c('div',[_c('span',{staticClass:"px-6 mr-15 text-end"},[_vm._v(" Total: $ "+_vm._s(_vm.$formatMoney(_vm.totalItems))+" ")])])]):_c('div',[_c('div',{staticClass:"py-4 px-6 mr-15 text-end font-weight-bold"},[_c('span',[_vm._v(" Total: $ "+_vm._s(_vm.$formatMoney(_vm.totalItems*1 + _vm.tramite.gasto*1))+" ")])])]),_c('hr',{})],1)],1)],1),_c('v-row',{staticClass:"finalizado__row",style:({ borderRadius: '10px', margin: '4px' })},[_c('v-col',{staticClass:"text-left font-weight-bold",attrs:{"cols":"auto","md":"10"}},[_c('router-link',{attrs:{"to":_vm.routePedidoFinalizado}},[_vm._v("[Nombre del Pedido] - Pedido Nro 999999999 ")])],1),_c('v-col',{attrs:{"cols":"auto","md":"2"}},[_c('router-link',{attrs:{"to":"/EjemploImagen"}},[_c('BaseButton',{attrs:{"color":"btnPrimary"}},[_vm._v(" Confirmar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }