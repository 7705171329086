<template>
    <div :class="isRouteElementosAduanaCompra ? 'flex__clienteAduana__elementos' : 'flex__clienteAduana__detalleP'">
        <v-row>
            <v-col xs="12" sm="10" :md="isRouteElementosAduanaCompra ? 5 : 4">
            <span><b>Cliente:</b> {{ clienteLocal.name }} {{ clienteLocal.lastName }}</span>
            </v-col> 
            <v-col xs="12" sm="10" :md="isRouteElementosAduanaCompra ? 4 : 4">
                <span><b>Documento:</b> {{ clienteLocal.documento }} </span>
            </v-col>
            <v-col  v-if="isRouteElementosAduanaCompra" xs="12" sm="10" md="2">
                <router-link to="/elementos_aduana_venta">
                    <BaseButton
                        color="btnSecondary"
                        size="small"
                        icon="mdi-cached"
                        iconPosition="right"
                        > Cambiar Cliente
                        </BaseButton>
                </router-link>
            </v-col>
        </v-row>
        <!-- Modal Cliente -->
        <v-dialog v-model="modalOpen">
            <v-card>
                <div class="custom__modal">
                    <v-card-text>
                        <ElementosAduanaVenta 
                            ref="ElementosAduanaVenta"
                            :cliente="cliente" 
                            :sourceClienteAduana="true"
                            @cliente-seleccionado="clienteSelected"
                        ></ElementosAduanaVenta>
                    </v-card-text>
                </div>
                <v-card-actions>
                    <v-btn 
                        color="primary" 
                        @click="closeModalCliente()"
                    >Cerrar
                    </v-btn>
                    <v-btn 
                        :color="$cv('principal')"
                        @click="updateCliente()" 
                        >
                        Actualizar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--// Modal Cliente -->
    </div>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from 'vuex';
import ElementosAduanaVenta from "@/views/portal/ElementosAduanaVenta.vue";
import { BaseButton } from "../../../../components/design-system";

export default {
    name: 'ClienteAduana',

    components: {
        ElementosAduanaVenta,
        BaseButton,
    },

    props: {
        cliente: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            title: '2. Selecciona el/los producto/s a vender',
            modalOpen: false,
            sourceClienteAduana: true,
            selectedCliente: null, 
        };
    },

    computed: {
        ...mapGetters('aduana', ['getClienteLocal']),
        
        isRouteElementosAduanaCompra(){ 
            return this.$route.path === '/elementos_aduana_venta_seleccion';
        }
    },

    watch: {

        cliente: {
            handler(nuevoCliente){
                this.clienteLocal = { ...nuevoCliente  };
            },
            immediate: true
        }
        
    },

    mounted() {
        
    },
    

    methods: {
        ...mapActions('aduana', ['actualizarClienteLocal']),
        ...mapMutations('aduana', ['setClienteLocal']),

        /*
        openModalCliente() {
            this.$nextTick(() => {
                if (this.$refs.ElementosAduanaVenta) {
                    this.initFilterFields();
                    this.deselectAllCliente();
                    this.clearFilter();
                } else {
                    console.warn('El componente ElementosAduanaVenta no se monto.');
                }
                this.initialCliente = { ...this.getClienteLocal };
                this.modalOpen = true;
            });
        },*/

        closeModalCliente(){
            this.actualizarClienteLocal(this.getClienteLocal);
            this.modalOpen = false;
        },
        
        updateCliente() {
            this.$emit('update:cliente', this.getClienteLocal);
            this.modalOpen = false;
        },

        clienteSelected(clienteSeleccionado) {
            this.setClienteLocal(clienteSeleccionado);
            this.actualizarClienteLocal(clienteSeleccionado);
        },

        deselectAllCliente() {
            this.$refs.ElementosAduanaVenta.deselectAllCliente();
        },

        clearFilter() {
            this.$refs.ElementosAduanaVenta.clearFilter();
        },

        initFilterFields() {
            this.$refs.ElementosAduanaVenta.initFilterFields();
        },
    },
};
</script>

<style>

.custom__modal {
    max-height: 500px;
    overflow-y: auto;
}

.btn__rounded {
  text-decoration: none;
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.btn__margin {
    margin-top: 29px;
}

.flex__clienteAduana__elementos{
  display: flex ;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5rem;
  padding-top: 1rem;
  border: 1px solid lightgrey;
  border-radius: 0.5rem; 
}

.flex__clienteAduana__detalleP {
  display: flex ;
  flex-direction: row;
  padding: 1rem;
  margin-left: 1.3rem;
  padding-top: 0;
  margin-left: 1.3rem;
}



</style>