<template>
  <div>
    <v-row>
      <v-col cols="auto" md="12">
        <v-card
          border
          class=""
          :style="{
            borderRadius: '10px',
            margin: '4px',
            backgroundColor: $cv('primary') + '20',
          }"
          ><h2 class="text-center">
            Pedido Realizado
          </h2>
          <ClienteAduana
            v-if="sourceVentaElementosAduanaSt"
            :cliente="clienteLocal"
          ></ClienteAduana>
          <hr class="" />
          <div class="items__div">
            <div v-for="(item, index) in items" :key="index" class="py-3">
              <div class="div__vfor">
                <v-col cols="auto" md="2" class="text-center">
                    {{ item.codigo }}
                </v-col>
                <v-col cols="auto" md="6">
                  {{ item.nombre }}
                  
                    Precio por Unidad: ${{
                      item.precio ? $formatMoney(item.precio) : ""
                    }}
                  
                </v-col>
                <v-col cols="auto" md="2">
                  Cantidad: {{ item.cantidad }}
                </v-col>
                <v-col cols="auto" md="2" class="text-end">
                  ${{ $formatMoney(item.subtotal) }}
                </v-col>
              </div>
              <hr v-if="index + 1 < items.length" />
            </div>
            <div
              v-if="
                tramite.tipogasto != 'Saldo a Favor:' &&
                tramite.tipogasto != 'Débito:'
              "
            >
              <hr />
              <div class="div__gastos py-3 font-weight-bold">
                {{ tramite.tipogasto }} &nbsp;
                ${{ $formatMoney(tramite.gasto) }}
              </div>
            </div>
          </div>
          <hr class="" />
          <div
            v-if="
              tramite.tipogasto == 'Saldo a Favor:' ||
              tramite.tipogasto == 'Débito:'
            "
            class="div_total">
            <div class="div_total">
              <span class="px-6 my-4 text-end">Forma de pago</span>
              <span class="px-6 my-4 text-start">
                {{ tramite.tipogasto }} &nbsp; {{ tramite.gasto }}
              </span>
            </div>
            <div>
              <span class=" px-6 mr-15 text-end">
                Total: $ {{ $formatMoney(totalItems) }}
              </span>
            </div>
          </div>
          <div v-else>
            <div class="py-4 px-6 mr-15 text-end font-weight-bold">
              <span >
                Total: $ {{ $formatMoney(totalItems*1 + tramite.gasto*1) }}
              </span>
            </div>
          </div>
          <hr class="" />
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="finalizado__row"
      :style="{ borderRadius: '10px', margin: '4px' }"
    >
      <v-col cols="auto" md="10" class="text-left font-weight-bold">
        <router-link :to="routePedidoFinalizado"
          >[Nombre del Pedido] - Pedido Nro 999999999
        </router-link>
      </v-col>
      <v-col cols="auto" md="2" >
        <router-link to="/EjemploImagen">
          <BaseButton
            color="btnPrimary"
          >
            Confirmar
          </BaseButton>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ClienteAduana from "@/views/dashboard/components/secundarios/ClienteAduana.vue";
import { BaseButton } from "../../components/design-system";

export default {
  components: {
    ClienteAduana,
    BaseButton,
  },

  computed: {
    ...mapState("carrito", ["carrito", "tramitePago", "total"]),
    ...mapState("aduana", ["clienteLocal", "sourceVentaElementosAduanaSt"]),
  },

  data() {
    return {
      routePedidoFinalizado: "",
      items: [],
      totalItems: Number(),
      tramite: {},
    };
  },

  methods: {
    ...mapMutations("carrito", ["vaciarCarrito"]),
    compraTerminada() {
      this.vaciarCarrito();
      window.open(
        "http://192.168.1.34:8081/assets/muestraRemito.pdf",
        "_blank"
      );
      //window.location.href = "/";
    },
  },

  mounted() {
    this.items = this.carrito;
    this.totalItems = this.total;
    this.tramite = this.tramitePago;
    this.vaciarCarrito();
    //console.log(this.$route.path);
    switch (this.$route.path) {
      case "/PedidoFinalizadoAdquirirSaldo":
        this.routePedidoFinalizado = "/EjemploImagenAdquirirSaldo";
        break;
      default:
        this.routePedidoFinalizado = "/EjemploImagen";
        break;
    }
  },
};
</script>

<style>
.div_totales {
  display: flex;
}
.items__div {
  overflow: auto;
  max-height: 37rem;
}
.finalizado__row {
  display: flex;
  align-items: center;
  max-height: 7rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.5rem gray;
}
.div__gastos {
  display: flex;
  justify-content: end;
  margin-right: 9%;
  padding: 0.5rem;
}
.div_total {
  display: flex;
  justify-content: space-between;
}
.routerLink__finalizar {
  background-color: #011f5b;
  color: white !important;
  padding: 1rem 3rem;
  text-decoration: none;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}
</style>
