<template>
  <v-container id="crud" fluid tag="section">
    <v-row>
      <v-col :md="sourceClienteAduana ? 12 : 10">
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="clientes"
              :search="search"
              item-key="documento"
              show-select
              :single-select="true"
              @input="onSelectionChanged"
              no-data-text="No se ha encontrado al cliente solicitado."
              class="elevation-4"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-row>
                    <v-col xs="12" sm="10" md="10" class="pt-10">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-account-search-outline"
                        label="Búsqueda de Clientes Aduana"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col xs="12" sm="2" md="2" class="pt-10 m-0">
                      <div v-if="!sourceClienteAduana">
                        <router-link
                          v-if="$can(['alta_cliente'])"
                          to="/cliente_alta"
                        >
                          <BaseButton color="primary" size="small">
                            Nuevo Cliente
                          </BaseButton>
                        </router-link>
                      </div>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:item.phone="{ item }">
                <span>{{ sensitiveData(item.phone) }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-end">
            <BaseButton
              v-if="!sourceClienteAduana"
              color="btnSecondary"
              variant="outlined"
              class="mr-4"
              @click="$goBack()"
            >
              Cancelar
            </BaseButton>
            <BaseButton
              v-if="!sourceClienteAduana"
              color="btnPrimary"
              class="mr-0"
              :disabled="disabled"
              @click="nextPage()"
            >
              Continuar
            </BaseButton>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { BaseButton } from "../../components/design-system";

export default {
  name: "ElementosAduanaVenta",

  components: { BaseButton },

  props: {
    sourceClienteAduana: {
      type: Boolean,
    },
  },

  data() {
    return {
      search: "",
      title: "1. Elige el cliente",
      documento: "",
      nombre: "",
      headers: [
        { text: "", value: "check" },
        { text: "Nombre", value: "businessName" },
        { text: "Documento", value: "documento" },
        { text: "Ciudad", value: "localidad" },
        { text: "Celular", value: "phone" },
        { text: "Mail", value: "email" },
      ],

      clientesOriginal: [],
      clienteChosen: "",
      disabled: true,
    };
  },

  mounted() {
    this.deselectAllCliente();
    this.fetchClientesAduana();
  },

  computed: {
    ...mapState("aduana", { clientes: "clientesAduana" }),

    clienteSelected() {
      return this.clientes.some((cliente) => cliente.selected);
    },
  },

  methods: {
    ...mapActions("aduana", ["fetchClientesAduana"]),

    sensitiveData(data) {
      return "*".repeat(data.length);
    },

    clearFilter() {
      this.clientes = [...this.clientesOriginal];
    },

    filter() {
      const filterDocumento = this.documento.trim();
      const filterNombre = this.nombre.toLowerCase().trim();

      this.clientes = this.clientes.filter((cliente) => {
        const documento = cliente.documento;
        const nombre = cliente.nombre.toLowerCase();

        return (
          (documento.includes(filterDocumento) || filterDocumento === "") &&
          (nombre.includes(filterNombre) || filterNombre === "")
        );
      });
    },

    selectCliente(selectedCliente) {
      this.clienteChosen = selectedCliente;
      this.clientes.forEach((cliente) => {
        if (cliente != selectedCliente) {
          cliente.selected = false;
        }
      });
      this.$emit("cliente-seleccionado", this.clienteChosen);
    },

    nextPage() {
      if (this.clienteChosen) {
        this.$router.push({
          name: "elementos_aduana_venta_seleccion",
          params: {
            cliente: this.clienteChosen,
            sourceVentaElementosAduana: true,
          },
        });
      }
    },

    changeAgain(selectedCliente) {
      this.clienteChosen = selectedCliente;
      this.clientes.forEach((cliente) => {
        if (cliente != selectedCliente) {
          cliente.selected = false;
        }
      });
      this.$emit("cliente-seleccionado", this.clienteChosen);
    },

    deselectAllCliente() {
      this.clientes.forEach((cliente) => {
        cliente.selected = false;
      });
    },

    initFilterFields() {
      this.documento = "";
      this.nombre = "";
    },

    onSelectionChanged(selectedItems) {
      console.log("Filas seleccionadas:", selectedItems);
      if (selectedItems.length > 0) {
        this.clienteChosen = selectedItems[0];
        this.disabled = false;
        console.log("Filas elegidas:", this.clienteChosen);
      } else {
        this.disabled = true;
        console.log("No se han seleccionado filas.");
      }
    },
  },

  created() {
    this.clientesOriginal = JSON.parse(JSON.stringify(this.clientes));
  },
};
</script>
<style>
.btn__rounded {
  text-decoration: none;
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.btn__margin {
  margin-top: 29px;
}

.spacer {
  margin-right: 8px;
}
.input__label {
  margin-bottom: 3px;
}

.input__field {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
}

.custom__table th {
  background-color: #cfd8dc;
  color: #333 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.custom__table thead th:first-child {
  border-top-left-radius: 10px;
}

.custom__table thead th:last-child {
  border-top-right-radius: 10px;
}

.alert__msj {
  width: 330px;
  border-radius: 0.5rem;
}

.custom__checkbox {
  padding: 0;
  margin: 0;
  width: 10px;
}
</style>
