<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :elevation="elevation"
    :style="commonStyle"
    v-bind="buttonProps"
    v-on="$listeners"
  >
    <v-icon v-if="showIcon('left')" :class="iconClassLeft">{{ icon }}</v-icon>
    <slot></slot>
    <v-icon v-if="showIcon('right')" :class="iconClassRight">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton',

  props: {
    color: {
      type: String,
      default: 'accent',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: String,
      default: '',
    },
    shape: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    options: {
      type: [String, Array],
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
  },

  data() {
    return {
      
    };
  },
  computed: {

    buttonProps() {
      const optionsArray = Array.isArray(this.options) ? this.options : [this.options];

      return {
        //Tamaño
        'x-large': this.size === 'x-large',
        large: this.size === 'large',
        small: this.size === 'small',
        'x-small': this.size === 'x-small',
        //Formato
        rounded: this.shape === 'rounded',
        fab: this.shape === 'fab',
        //Variantes 
        outlined: this.variant === 'outlined',
        tonal: this.variant === 'tonal',
        text: this.variant === 'text',
        plain: this.variant === 'plain',
        elevated: this.variant === 'elevated',
        //Otras opciones
        block: optionsArray.includes('block'),
      }
    },

    commonStyle() {
      return {
        'font-family': 'Roboto',
        'text-transform': 'capitalize',
        'padding': '0px 16px !important',
      }
    },

    iconClassLeft() {
      return this.$slots.default ? 'icon-spacing-left' : '';
    },

    iconClassRight() {
      return this.$slots.default ? 'icon-spacing-right' : '';
    },

  }, 
  
  methods: {

    showIcon(position) {
      return this.iconPosition === position && this.icon;
    },

  },
};
</script>


<style scoped>
.icon-spacing-left {
  margin-right: 5px;
}

.icon-spacing-right {
  margin-left: 5px;
}
</style>